// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-template-js": () => import("./../../../src/components/templates/blog.template.js" /* webpackChunkName: "component---src-components-templates-blog-template-js" */),
  "component---src-components-templates-post-template-js": () => import("./../../../src/components/templates/post.template.js" /* webpackChunkName: "component---src-components-templates-post-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */)
}

